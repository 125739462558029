<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    scrollable
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn :disabled="!item || !item.id" icon @click="show">
        <v-icon>change_history</v-icon>
      </v-btn>
    </template>
    <v-card v-if="dialog">
      <v-toolbar class="my-bar">
        <v-toolbar-title>Просмотр изменений</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <div class="view" style="max-height: calc(100vh - 64px)">
        <div class="my-content">
          <v-form ref="form" style="height: 100%">
            <v-simple-table fixed-header dense style="height: 100%">
              <template v-slot:default>
                <thead>
                <tr>
                  <th>№</th>
                  <th>Состояние</th>
                  <th>Дата</th>
                  <th>Пользователь</th>
                  <th>Дата поступления</th>
                  <th>Город</th>
                  <th>Текст претензии</th>
                  <th>Действие</th>
                  <th>Дата исполнения</th>
                  <th>Оценка</th>
                  <th>ЛПУ/SOSMA</th>
                  <th>Статус претензии</th>

                </tr>
                </thead>
                <tbody v-for="(item, i) in items" :key="i">
                <ComplaintHistoryItem
                  :item="item"
                  :index="i"
                ></ComplaintHistoryItem>
                </tbody>
              </template>
            </v-simple-table>
          </v-form>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import ctx from "@/ctx";
import fetch from "../../utils/fetch";

import datetimeLocalFilter from "../../filters/datetimeLocal.filter";
import ComplaintHistoryItem from "@/components/insured/ComplaintHistoryItem";
import dateIsoFilter from "@/filters/dateIso.filter";

const stateLocalizationDictionary = {
  UPDATE: "Обновлено",
  DELETE: "Удалено",
  CREATE: "Создано"
};

let Item = function(d) {
  let self = this;

  self.date2vm = function(val) {
    if (!val) return;
    return datetimeLocalFilter(val);
  };

  self.date = self.date2vm(d.date);
  self.state = stateLocalizationDictionary[d.state];
  self.userName = d.userName;

  self.additional = d.additional;
  if (!self.additional) {
    self.additional = [];
  }
  if(self.additional) {
    self.additional.estimationLabel = d.estimationLabel
    self.additional.statusLabel = d.statusLabel
    if(!!self.additional.text && self.additional.text.length >= 9) {
      self.additional.text = self.additional.text.substring(0, 8) + "..."
    }
    if(self.additional.executionDate) self.additional.executionDate = dateIsoFilter(self.additional.executionDate)
    if(self.additional.filingDate) self.additional.filingDate = dateIsoFilter(self.additional.filingDate)

  }

};

export default {
  props: {
    item: Object
  },
  data: () => ({
    items: [],
    dialog: false,
    loading: false
  }),
  methods: {
    async show() {
      this.items = [];
      this.loading = true;
      this.dialog = true;
      const res = await fetch.get(
        "/api/complaint/logs/" + this.item.id
      );
      if (res) res.forEach(r => this.items.push(new Item(r)));
      console.log(ctx.src.dict['INS_CITY'].items)
      this.loading = false;

    }
  },
  mounted() {
    this.items = [];

  },
  components: {
    ComplaintHistoryItem
  }
};
</script>

<style>
th {
  text-align: left;
}
</style>
