<template>
  <v-form ref="form" v-model="valid" style="overflow: auto">
    <v-alert v-if="closedComplaint" type="info">Данная претензия была закрыта для изменения</v-alert>

    <v-container>
      <v-row>
        <v-col class="d-flex" cols="12" sm="12">
          <v-text-field v-model="complaintItem.filingDate" type="date" label="Дата поступления"
                        :rules="[rules.required]"
                        :readonly="itemIsReadonly"></v-text-field>
        </v-col>
        <v-col class="d-flex" cols="12" sm="12">
          <v-text-field v-model="complaintItem.executionDate" type="date" label="Дата исполнения"
                        :readonly="itemIsReadonly" :rules="[validateExecutionDate]"
          ></v-text-field>
        </v-col>
        <v-col class="d-flex" cols="12" sm="12">
          <v-textarea v-model="complaintItem.text" label="Текст" :rules="[rules.required]" :readonly="itemIsReadonly"></v-textarea>
        </v-col>
        <v-col class="d-flex" cols="12" sm="12">
          <v-textarea v-model="complaintItem.action" label="Действие" :rules="[rules.required]" :readonly="itemIsReadonly"></v-textarea>
        </v-col>
        <v-col class="d-flex" cols="12" sm="12">
          <v-select v-model="complaintItem.estimation" :items="estimation" label="Оценка" :readonly="itemIsReadonly"
                    :rules="[rules.required]"
          ></v-select>
        </v-col>
        <v-col class="d-flex" cols="12" sm="12">
          <v-autocomplete
            v-model="complaintItem.medicalCenterId"
            :items="ctx.src.org.activeByType('3')"
            :label="'Мед центр'"
            item-text="fullName"
            item-value="id"
            :rules="[rules.required]"
            :readonly="itemIsReadonly"
          ></v-autocomplete>
        </v-col>
        <v-col class="d-flex" cols="12" sm="12">
          <v-select v-model="complaintItem.status" label="Статус претензии" :items="statuses"
                    :rules="[rules.required]"
                    :readonly="itemIsReadonly"
          ></v-select>
        </v-col>
        <v-col class="d-flex" cols="12" sm="12">
          <v-autocomplete
            v-model="complaintItem.cityId"
            :items="ctx.src.dict['INS_CITY'].items"
            :label="'Город'"
            item-text="value"
            item-value="id"
            :rules="[rules.required]"
            :readonly="itemIsReadonly"
          ></v-autocomplete>
        </v-col>


      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import { requiredRule } from "@/utils/staticData/commonRules";
import ctx from "@/ctx";
import accessFilter from "@/filters/access.filter";
import _ from "lodash";

export default {
  props: {
    item: Object,
  },
  data() {
    return {
      ctx,
      complaintItem: {},
      rules: {
        required: requiredRule,
      },
      valid: false,
      statuses: [
        { text: "В работе", value: "OPEN" },
        { text: "Закрыта", value: "CLOSED" },
      ],
      estimation: [
        { text: "Обоснованная", value: "REASONABLE" },
        { text: "Необоснованная", value: "UNREASONABLE" },
      ],
      isInternalChange: false
    };
  },
  watch: {
    item: {
      handler(val) {
        this.complaintItem = _.cloneDeep(val);
      },
      immediate: true,
    },
    complaintItem: {
      handler() {
        if(!this.isInternalChange) {
          this.isInternalChange = true;
          this.$emit("itemChange", this.saveButtonActive, this.complaintItem);

        }
        this.$nextTick(() => {
          this.validate();
          this.isInternalChange = false;
        });
      },
      deep: true,
    }
  },
  computed: {
    closedComplaint() {
      return this.item.status === "CLOSED" ;
    },
    currentUserCannotChangeClosedComplaint() {
      return !accessFilter("CLOSED_COMPLAINTS")
    },
    saveButtonActive() {
      return this.valid && !this.itemIsReadonly;
    },
    itemIsReadonly() {
      return this.closedComplaint && this.currentUserCannotChangeClosedComplaint
    }
  },
  methods: {
    init() {
      this.complaintItem = _.cloneDeep(this.item);
      this.$nextTick(() => this.validate());
    },
    validate() {
      this.$refs.form.validate();
    },
    validateExecutionDate(value) {
      return !!value || !this.item.closedComplaint || "Обязательное поле"
    }
  },
  mounted() {
    this.init();
  },
};
</script>
