<template>
  <tr>
    <td>{{ index + 1 }}</td>
    <td>{{ item.state }}</td>
    <td>{{ item.date }}</td>
    <td>{{ item.userName }}</td>
    <td>
      <v-text-field
        v-model="item.additional.filingDate"
        type="date"
        readonly
      ></v-text-field>
    </td>
    <td>{{ item.additional.cityLabel }}</td>
    <td>{{ item.additional.omittedText }}</td>
    <td>{{ item.additional.action }}</td>
    <td>
      <v-text-field
        v-model="item.additional.executionDate"
        type="date"
        readonly
      ></v-text-field>
    </td>
    <td>{{ item.additional.estimationLabel }}</td>
    <td>{{ item.additional.medicalCenterLabel }}</td>
    <td>{{ item.additional.statusLabel }}</td>
  </tr>
</template>

<script>
import ctx from "@/ctx";

export default {
  props: {
    item: Object,
    index: Number
  },
  data: () => ({
    ctx
  }),

};
</script>
<style>
td {
  text-align: left;
}
</style>
