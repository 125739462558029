<template>
  <div class="view-wrapper this-wrapper">
    <div class="view">
      <v-toolbar class="my-bar">
        <v-btn icon @click="add">
          <v-icon>add</v-icon>
        </v-btn>
        <v-btn
          icon
          :disabled="deleteButtonDisabled"
          @click="toggleDeletionDialog"
        >
          <v-icon>remove</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <ComplaintHistory :item="item"></ComplaintHistory>
        <v-dialog v-model="deleteDialogShown" max-width="500px">
          <v-card>
            <v-card-title class="headline"
            >Вы точно хотите удалить претензию?
            </v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="toggleDeletionDialog">Отмена</v-btn>
              <v-btn color="primary" text @click="deleteComplaint">OK</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
      <v-data-table :headers="headers" :items="items" :single-select="true"
                    loading-text="Загрузка... Пожалуйста подождите" :items-per-page="-1" item-key="id"
                    fixed-header @click:row="rowClick">
      </v-data-table>
    </div>
    <div v-if="detailShown" class="view">
      <v-toolbar class="my-bar">
        <SaveButton :handler="save" :additional-rules="!saveButtonActive"></SaveButton>
        <v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <ComplaintDetail :item="item" @itemChange="handleItemChange"></ComplaintDetail>
    </div>
  </div>
</template>

<script>
import ComplaintDetail from "@/components/insured/ComplaintDetail";
import SaveButton from "@/components/ButtonFacade";
import fetch from "@/utils/fetch";
import ctx from "@/ctx";
import dateIsoFilter from "@/filters/dateIso.filter";
import accessFilter from "@/filters/access.filter";
import ComplaintHistory from "@/components/insured/ComplaintHistory";
import _ from "lodash";

export default {
  components: {ComplaintHistory, ComplaintDetail, SaveButton},
  props: {
    insuredId: Number
  },
  data() {
    return {
      headers: [
        {
          "text": "№",
          "value": "index"
        },

        {
          "text": "Дата поступления",
          "value": "filingDate"
        },
        {
          "text": "Текст претензии",
          "value": "omittedText"
        },
        {
          "text": "Действие",
          "value": "action"
        },
        {
          "text": "Дата исполнения",
          "value": "executionDate"
        },
        {
          "text": "Оценка",
          "value": "estimationLabel"
        },
        {
          "text": "ЛПУ/SOSMA",
          "value": "medicalCenterLabel"
        },
        {
          "text": "Статус Претензии",
          "value": "statusLabel"
        },
        {
          "text": "Город",
          "value": "cityLabel"
        }
      ],
      items: [],
      item: null,
      deleteDialogShown: false,
      detailShown: false,
      saveButtonActive: false

    }
  },
  watch: {
    insuredId: function () {
      this.fetchItems()
    }
  },
  methods: {
    async fetchItems() {
      this.loading = true;
      const items = await fetch.get(`/api/complaint/get?insuredId=${this.insuredId}`)
      this.items = items.map(item => this.mapItem(item))
      this.setIndexes()
      this.loading = false;
    },
    async save() {
      this.loading = true
      await fetch.post(`/api/complaint/create`, this.item)
      await this.fetchItems()
      this.close()
      this.loading = false
    },
    rowClick(obj, row) {
      row.select(obj)
      this.item = obj
      this.detailShown = true
    },
    add() {
      this.item = {
        id: null,
        insuredId: this.insuredId
      }
      this.detailShown = true;
    },
    close() {
      this.item = {}
      this.detailShown = false;
    },
    async deleteComplaint() {
      this.loading = true
      await fetch.delete(`/api/complaint/delete/${this.item.id}`)
      await this.fetchItems()
      this.toggleDeletionDialog()
      this.close()
      this.loading = false
    },
    setIndexes() {
      let index = 1;
      this.items.forEach(item => item.index = index++)
    },

    toggleDeletionDialog() {
      this.deleteDialogShown = !this.deleteDialogShown
    },

    handleItemChange(saveButtonActive, complaintItem) {
      console.log('ITEM CHANGE EVENT')
      console.log('SAVE BUTTON STATE', saveButtonActive)
      console.log('ITEM STATE', complaintItem)
      this.saveButtonActive = saveButtonActive;
      if (this.saveButtonActive) this.item = _.cloneDeep(complaintItem);
    },

    mapItem(item) {
      return {
        id: item.id,
        filingDate: dateIsoFilter(item.filingDate),
        text: item.text,
        action: item.action,
        executionDate: dateIsoFilter(item.executionDate),
        estimation: item.estimation,
        medicalCenterId: item.medicalCenterId,
        status: item.status,
        cityId: item.cityId,
        cityLabel: ctx.src.dict['INS_CITY'].items.find(i => i.id === item.cityId).value,
        omittedText: item.text.substring(0, 8) + '....',
        statusLabel: item.status === 'OPEN' ? "В работе" : "Закрыта",
        medicalCenterLabel: ctx.src.org.get(item.medicalCenterId).fullName,
        estimationLabel: item.estimation === 'REASONABLE' ? 'Обоснованная' : "Необоснованная",
        insuredId: item.insuredId,
        deleted: item.deleted
      }
    }
  },
  computed: {
    deleteButtonDisabled() {
      return this.item === null || this.item.id === null ||
        (this.item.status === 'CLOSED' && !accessFilter('CLOSED_COMPLAINTS'))
    },


  },


  mounted() {
    this.fetchItems()
  }
}
</script>

<style lang="scss" scoped>
.this-wrapper {
  height: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.this-wrapper::v-deep .v-data-table__wrapper {
  > table {
    counter-reset: section;

    tbody tr td:nth-child(3)::before {
      counter-increment: section;
      content: counter(section);
    }
  }
}
</style>
